<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.notes") }}</h3>
        <ul>
            <li>{{ $t("message.p.notes.p1") }}</li>
            <li>{{ $t("message.p.notes.p2") }}</li>
            <li>{{ $t("message.p.notes.p3") }}</li>
            
        </ul>
    
    
            <h3>{{ $t("message.h.setup") }}</h3>
        <ul>
            <li>{{ $t("message.p.setup.p1") }}</li>
            <li>{{ $t("message.p.setup.p2") }}</li>
            <li>{{ $t("message.p.setup.p3") }}</li>
            <li>{{ $t("message.p.setup.p4") }}</li>
            <li>{{ $t("message.p.setup.p5") }}</li>
            <li>{{ $t("message.p.setup.p6") }}</li>
            <li>{{ $t("message.p.setup.p7") }}</li>
            <li>{{ $t("message.p.setup.p8") }}</li>
            
        </ul>
    
    
            <h3>{{ $t("message.h.run") }}</h3>
        <ul>
            <li>{{ $t("message.p.run.p1") }}</li>
            <li>{{ $t("message.p.run.p2") }}</li>
            <li>{{ $t("message.p.run.p3") }}</li>
            <li>{{ $t("message.p.run.p4") }}</li>
            <li>{{ $t("message.p.run.p5") }}</li>

            
        </ul>
    </div>

</template>



<script>
export default {
    name: "HelpEc3",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Help';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "EC3",
                        notes: "Notes",
                        setup: "Defining restraints",
                        run: "Prepare EC3 check data"

                    },
                    a: {

                    },

                    p: {
                        notes: {
                            p1: "The buckling information is stored in nodes of the type node on line. This information also persists if you copy a member with its nodes on line.",
                            p2: "It is recommended to run the Buckle command for all members any time before you open the EC3 module.",
                            p3: "RFEM hides all comments by default. If you cannot see the comments created by this tool please double check if this checkbox has been checked: Project Navigator, Display, Guide Objects, Comments, All"


                        },
                        setup: {
                            p1: "Add a node on line wherever you need to define a lateral support.",
                            p2: "Create at least one EC3 case with stability check",
                            p3: "Start RFEX",
                            p4: "Switch to tab page Nodes",
                            p5: "Select nodes",
                            p6: "Set buckling Y and/or Z for the calcuation of a reduced buckling length or",
                            p7: "Set LatSup for Intermediate lateral supports",
                            p8: "Click apply to store that information",
                        },
                        run: {
                            p1: "Start RFEX",
                            p2: "Switch to tabpage Members",
                            p3: "Select members you want to be updated (As this operation is fast, it is safe to just select all)",
                            p4: "A click on Buckle will update all buckling configurations in all EC3 cases.",
                            p5: "Open EC3 module and run checks"

                        }

                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "EC3",
                        notes: "Notizen",
                        setup: "Festhaltungen definieren",
                        run: "EC3 Daten aktualisieren"

                    },
                    a: {

                    },

                    p: {
                        notes: {
                            p1: "Die Knickinformation wird in Knoten vom Typ Knoten auf Linie gespeichert. Diese Information bleibt also auch erhalten, wenn ein Stab gemeinsam mit seinen Knoten weiter kopiert wird.",
                            p2: "Es wird empfohlen, den Befehl Buckle zur Aktualisierung der EC3 Daten immer zu starten, bevor das EC3 Modul berechnet wird.",
                            p3: "RFEM zeigt standardmäßig keine Kommentare an. Sollten die mit diesem Tool erzeugten Kommentare nicht sichtbar sein, bitte diese Einstellung prüfen: Projekt Navigator, Zeigen, Hilfsobjekte, Kommentare, Alle"


                        },
                        setup: {
                            p1: "Knoten auf Linien erzeugen, wo eine seitliche Festhaltung vorhanden ist",
                            p2: "Mindestens einen EC3 Fall mit Stabilitätsuntersuchung anlegen",
                            p3: "RFEX starten",
                            p4: "Zu Reiter Nodes wechseln",
                            p5: "Knoten auswählen",
                            p6: "Entweder Y und/oder Z zur Berechnung der reduzierten Knicklänge oder",
                            p7: "LatSup für seitliche Zwischenhalterungen einstellen",
                            p8: "Auf apply klicken, um Daten zu speichern.",
                        },
                        run: {
                            p1: "RFEX starten",
                            p2: "Wechseln zum Reiter Members",
                            p3: "Stäbe auswählen, welche aktualisiert werden sollen (Da der Vorgang sehr kurz dauert, kann hier einfach All gewählt werden)",
                            p4: "Mit Klick auf Buckle werden die Knickeinstellungen für die selektierten Stäbe in allen EC3 Fällen aktualisiert.",
                            p5: "EC3 Modul öffnen und berechnen"

                        }

                    }
                }
            },
        }
    }
};
</script>