<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.notes") }}</h3>
        <ul>
            <li>{{ $t("message.p.notes.p1") }}</li>
            <li>{{ $t("message.p.notes.p2") }}</li>
            <li>{{ $t("message.p.notes.p3") }}</li>
            <li>{{ $t("message.p.notes.p4") }}</li>
            <li>{{ $t("message.p.notes.p5") }}</li>
            <li>{{ $t("message.p.notes.p6") }}</li>
        </ul>

        <h3>{{ $t("message.h.setup") }}</h3>
        <ul>
            <li>{{ $t("message.p.setup.p1") }}</li>
            <li>{{ $t("message.p.setup.p2") }}</li>
            <li>{{ $t("message.p.setup.p3") }}</li>
            <li>{{ $t("message.p.setup.p4") }}</li>
            <li>{{ $t("message.p.setup.p5") }}</li>
            <li>{{ $t("message.p.setup.p6") }}</li>
            <li>{{ $t("message.p.setup.p7") }}</li>
            <li>{{ $t("message.p.setup.p8") }}</li>
            <li>{{ $t("message.p.setup.p9") }}</li>
            <li>{{ $t("message.p.setup.p10") }}</li>
            <li>{{ $t("message.p.setup.p11") }}</li>
            <li>{{ $t("message.p.setup.p12") }}</li>
        </ul>

        <h3>{{ $t("message.h.push") }}</h3>
        <ul>
            <li>{{ $t("message.p.push.p1") }}</li>
            <li>{{ $t("message.p.push.p2") }}</li>
            <li>{{ $t("message.p.push.p3") }}</li>
            <li>{{ $t("message.p.push.p4") }}</li>
            <li>{{ $t("message.p.push.p5") }}</li>
            <li>{{ $t("message.p.push.p6") }}</li>
            <li>{{ $t("message.p.push.p7") }}</li>
            <li>{{ $t("message.p.push.p8") }}</li>
            <li>{{ $t("message.p.push.p9") }}</li>
        </ul>

                <h3>{{ $t("message.h.bestpractice") }}</h3>
        <ul>
            <li>{{ $t("message.p.bestpractice.p1") }}</li>
            <li>{{ $t("message.p.bestpractice.p2") }}</li>
        </ul>
    </div>
</template>



<script>
export default {
    name: "HelpSubstructures",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Help';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Substructures",
                        notes: "Notes",
                        setup: "Setup",
                        push: "Push loading and calculating substructures",
                        bestpractice: "Best practise",
                    },
                    a: {

                    },

                    p: {
                        notes: {
                            p1: "Substructures are implemented as a member model link with a variable data source. This means that you you can have a (m:1) link between multiple members from a source model with one member of the target model instead of a (1:1) link between members of different models.",
                            p2: "The model containing the whole building and all the substructures (as a single replacement member) is called main model. You have to setup model links using the main model as a source model for each substructure as shown below.",
                            p3: "For calculating all substructures you have to open the main model and push the loading as shown below.",
                            p4: "This documentation assumes you want to work with trusses, but this is just an example for what can be done with this module.",
                            p5: "When testing the link you can of course open the target model and fetch the loads from the source model. But you will only get the loading from one replacement member of the source model (the one which is currently stored in the link).",
                            p6: "Load case number 99 is reserved for showing the final results of the EC3 steel check."

                        },
                        setup: {
                            p1: "Open the main model",
                            p2: "As a starting point add a new cross section from the library (e.g. the profile of the top chord)",
                            p3: "Rename the cross section (first column) from e.g. HEA 300 to the name of your truss, e.g. T100",
                            p4: "Feel free to adopt the section properties to fit your final truss (increase Iy to get realistic deformations)",
                            p5: "Add a single member as a replacement for one truss in the main model using the new cross section",
                            p6: "Now create a new model file called T100.rf5 (the name of the model must fit the cross section name)",
                            p7: "Create all truss members as usual",
                            p8: "Add supports",
                            p9: "Add at least one case in the EC3 steel check module",
                            p10: "Now add links between e.g. the upper chord and the replacement member from the main model",
                            p11: "Fetch loads to see if your setup works for this single member",
                            p12: "In the main model copy the replacement member as many times you need"
                        },
                        push: {
                            p1: "Open the main model",
                            p2: "Launch RFEX",
                            p3: "Switch to tab page members",
                            p4: "Select all members you want to calculate",
                            p5: "Switch to tabpage Cross Section",
                            p6: "Check the content of the read only select box. It contains the cross section numbers of all previously selected members.",
                            p7: "Click on Push loads",
                            p8: "Now RFEX is prepared to push the loading one by one to the substructure, calculates it thoroughly and checks the maximum degree of utilisation of all EC3 steel checks (you can have as many EC3 cases as you need).",
                            p9: "Have a look at load case 99 in the main model. You will see member loads representing the maximum degree of utilisation of the substructures steel check. Values larger than 1.0 are shown as negative values to help spotting them."
                        },
                        bestpractice: {
                            p1: "You want to change a truss type in the main model? Just change the cross section of this particular member to the other cross section and push the loads.",
                            p2: "You want to test changes to the substructures? First create a backup of T100.rf5 (e.g. T100_old.rf5) and modify T100 as you like. Run Push loads to calculate the substructure. To restore the original state just rename the file T100_old.rf5 back to T100.rf5 and rerun Push loads. By just renaming the files you can easily swap the substructures." 

                        }
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Substrukturen",
                        notes: "Hinweise",
                        setup: "Setup",
                      push: "Lasten übertragen und Substrukturen berechnen",
                    },
                    a: {

                    },

                    p: {
 notes: {
                            p1: "Substrukturen sind implementiert als verknüpfte Modelle mit variabler Datenquelle. Somit kann anstelle der (1:1) Verknüpfung eine (m:1) Verknüpfung zwischen mehreren Stäben aus dem Quellmodell mit einem Stab aus dem Zielmodell erzeugt werden.",
                            p2: "Das Modell, welches das komplette Tragwerk und alle Substrukturen als Ersatzstab enthält, wird in der Folge Hauptmodell genannt. Die Verknüpfungen zwischen den Modellen verwenden dieses Hauptmodell jeweils als Quellmodell.",
                            p3: "Um all Substrukturen zu berechnen, öffnet man das Hauptmodell und schreibt die Belastung in die Substrukturen (Push loads).",
                            p4: "Diese Dokumentation geht davon aus, dass die Substrukturen Fachwerke sind, aber das ist nur ein Beispiel für die Möglichkeiten, die dieses Modul bietet.",
                            p5: "Zum Testen der Verknüpfung kann jederzeit das Zielmodell geöffnet werden und die Lasten mittels Fetch loads geholt werden. Aber damit erhält man nur die Belastung eines Stabes (der aktuell in der Verknüpfung hinterlegt ist).",
                            p6: "Lastfall Nummer 99 ist reserviert für die Darstellung der Ergebnisse aus dem EC3 Modul."

                        },
                        setup: {
                            p1: "Hauptmodell öffnen",
                            p2: "Einfügen einens neuen Querschnitts, z.B. des Obergurts aus der Profilbibliothek.",
                            p3: "Umbenennen des Querschnittes (erste Spalte) von zB HEA 300 auf den Namen der Fachwerkposition, z.B. T100",
                            p4: "Die weiteren Querschnittsparameter dieses Querschnitts können beliebig angepasst werden (Iy erhöhen um realistische Verformungen zu erhalten)",
                            p5: "Einfügen eines Ersatzstabs im Hauptmodell mit diesem Querschnitt.",
                            p6: "Erstellen eines neuen Modells T100.rf5 im gleichen Modellordner (Der Name des Modells muss exakt dem Namen des Querschnittes entsprechen)",
                            p7: "Fachwerk modellieren wie üblich",
                            p8: "Auflager ergänzen",
                            p9: "Mindestens einen EC3 Lastfall anlegen",
                            p10: "Eine Verknüpfung zwischen dem Ersatzstab (Quellmodell) und zB dem Obergurt anlegen",
                            p11: "Fetch loads zum Testen der Verknüpfung",
                            p12: "Im Hauptmodell den Ersatzstab beliebig oft aufkopieren"
                        },
                        push: {
                            p1: "Hauptmodell öffnen",
                            p2: "RFEX starten",
                            p3: "Zum Reiter Members wechseln" ,
                            p4: "Alle Stäbe auswählen, welche berechnet werden sollen",
                            p5: "Zum Reiter Cross Section wechseln",
                            p6: "Den Inhalt der Auswahlbox kontrollieren. Er enthält alle Querschnittsnummern der im vorherigen Schritt markierten Träger.",
                            p7: "Push loads anklicken",
                            p8: "RFEX wird nun für jeden selektierten Stab der Reihe nach die Belastung an die Substruktur übertragen und diese vollständig berechnen. Der maximale Auslastungsgrad des EC3 Moduls wird ausgelesen (es können beliebig viele EC3 Fälle angelegt sein).",
                            p9: "Im Lastfall 99 des Hauptmodells wird für jeden Ersatzstab dieser Auslastungsgrad als Stablast dargestellt. Werte größer als 1,0 werden als negative Werte dargestellt, um Überschreitungen im Nachweis leichter zu finden."
                        },
                        bestpractice: {
                            p1: "Für einen Stab soll der Fachwerkstyp geändert werden? Einfach dem Ersatzstab einen anderen Querschnitt zuweisen und Stab neu berechnen (Push loads).",
                            p2: "Die Untersuchung von Alternativen für Substrukturen ist elegant möglich. Eine Backup Kopie der vorhandenen Substruktur erzeugen (z.B. T100_original.rf5), danach die T100.rf5 nach Wunsch bearbeiten. Push loads, um die Substruktur zu berechnen. Um den Originalzustand herzustellen, einfach die Dateien wieder umbenennen. Durch einfaches Umbenennen der Dateien können also Substrukturen sehr leicht ausgetauscht werden." 

                        }

                    }
                }
            },
        }
    }
};
</script>