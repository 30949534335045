import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from './plugins/font-awesome'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
})

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");


  