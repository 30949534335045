import axios from "axios";
import Cookies from 'js-cookie';

const devInstance = createInstance('http://localhost:8000/');
const productionInstance = createInstance('https://api.rfex.at/');

function createInstance(baseURL) {
    return axios.create({
        baseURL,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    });
}

const http= process.env.NODE_ENV === 'development'
?  devInstance 
:  productionInstance ;

export default http ;
