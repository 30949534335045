import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faKey,
  faDownload,
  faStar,
  faLink,
  faCubes,
  faCheck,
  faBuilding,
  faPen,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faKey,
  faDownload,
  faStar,
  faLink,
  faCubes,
  faCheck,
  faBuilding,
  faPen,
  faQuestion,
);

export { FontAwesomeIcon };
