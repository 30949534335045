<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <p>{{ $t("message.p.summary") }}</p>
        <ul>
            <li> {{ $t("message.p.tools.p1") }}</li>
            <li> {{ $t("message.p.tools.p2") }}</li>
            <li> {{ $t("message.p.tools.p3") }}</li>
        </ul>
    </div>
</template>



<script>
export default {
    name: "FeatModelling",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - ';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Modelling tools",

                    },
                    a: {

                    },

                    p: {
                        summary: "Small but useful helpers to speed up modelling (especially to clean up models imported from e.g. Tekla Structures)",
                        tools: {
                            p1: "Merge a set of members (which consists of multiple members) into a single member with nodes on lines.",
                            p2: "Detach nodes from a member (conversion node on line to default node)",
                            p3: "Attach nodes on a member (conversion default node to node on line)"
                        }
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Modellierungstools",

                    },
                    a: {

                    },

                    p: {
                        summary: "Kleine, aber praktische Tools, um die Bearbeitung von Modellen zu beschleunigen (ideal zum schnellen Nacharbeiten von z.B. aus Tekla importierten Modellen)",
                        tools: {
                            p1: "Konvertierung eines Stabsatzes in einen einzelnen Stab mit Knoten auf Linien",
                            p2: "Knoten von einem Stab abkoppeln (Konvertierung Knoten auf Linie in Standard Knoten)",
                            p3: "Knoten an einem Stab ankoppeln (Konvertierung Standard Knoten in Knoten auf Linie)",
                        }
                    }
                }
            }
        }
    }
};
</script>