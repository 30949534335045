<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.som") }}</h3>
        <ul>
            <li>{{ $t("message.p.som.p1") }}</li>
            <li>{{ $t("message.p.som.p2") }}</li>
            <li>{{ $t("message.p.som.p3") }}</li>
            <li>{{ $t("message.p.som.p4") }}</li>
        </ul>

        <h3>{{ $t("message.h.attach") }}</h3>
        <ul>
            <li>{{ $t("message.p.attach.p1") }}</li>
            <li>{{ $t("message.p.attach.p2") }}</li>
            <li>{{ $t("message.p.attach.p3") }}</li>
            <li>{{ $t("message.p.attach.p4") }}</li>
        </ul>
    </div>
</template>



<script>
export default {
    name: "HelpModelling",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Help';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Modelling tools",
                        som: "Converting a set of members into a single member",
                        attach: "Attach/Detach Nodes"
                    },
                    a: {

                    },

                    p: {
                        som: {
                            p1: "Launch RFEX",
                            p2: "Switch to tab SetOfMembers",
                            p3: "Select all Sets of Members you want to convert",
                            p4: "A click on MergeMembers will delete all but the first member of each Set of members. Then the remaining member will be stretched to the end node and all intermediate nodes will be converted into nodes of the type Node on Line"
                        },
                        attach: {
                            p1: "Launch RFEX",
                            p2: "Switch to tab page Members",
                            p3: "Select members you want to manipulate",
                            p4: "Click on Nodes+ to attach nodes or Nodes- to detach nodes"
                        }
                    }
                }
            },
            de: {
                message: {
 h: {
                        title: "Modellierung Tools",
                        som: "Konvertiere Stabsatz in einen einzelnen Stab",
                        attach : "Knoten an/abkoppeln (Konvertierung Standardknoten in Knoten auf Linie)"
                    },
                    a: {

                    },

                    p: {
                        som: {
                            p1: "RFEX starten",
                            p2: "Zu Reiter SetOfMembers wechseln",
                            p3: "Stabsätze auswählen, welche konvertiert werden sollen",
                            p4: "Ein Klick auf MergeMembers wird alle bis auf den ersten Stab des jeweiligen Stabsatzes löschen. Dann wird der erste Stab bis zum ursprünglichen Ende verlängert und alle Zwischenknoten an diesen Stab angekoppelt."
                        },
                        attach: {
                            p1: "RFEX starten",
                            p2: "Zu Reiter Members wechseln",
                            p3: "Stäbe auswählen, die bearbeitet werden sollen",
                            p4: "Klick auf Nodes+, um Knoten anzukoppeln oder Nodes-, um Knoten abzukoppeln."
                        }
                    }


                }
            },
        }
    }
};
</script>