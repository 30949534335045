<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.notes") }}</h3>
        <ul>
            <li>{{ $t("message.p.notes.p1") }}</li>
            <li>{{ $t("message.p.notes.p2") }}</li>
        </ul>
        <h3>{{ $t("message.h.match") }}</h3>
        <p>{{ $t("message.p.match.p1") }}</p>
        <ol>
            <li>{{ $t("message.p.match.p2") }}</li>
            <li>{{ $t("message.p.match.p3") }}</li>
            <li>{{ $t("message.p.match.p4") }}</li>
            <li>{{ $t("message.p.match.p5") }}</li>
            <li>{{ $t("message.p.match.p6") }}</li>
            <li>{{ $t("message.p.match.p7") }}</li>
            <li>{{ $t("message.p.match.p8") }}</li>
            <li>{{ $t("message.p.match.p9") }}</li>
            <li>{{ $t("message.p.match.p10") }}</li>
            <li>{{ $t("message.p.match.p11") }}</li>
            <li>{{ $t("message.p.match.p12") }}</li>
            <li>{{ $t("message.p.match.p13") }}</li>
        </ol>

        <h3>{{ $t("message.h.edit") }}</h3>
        <ol>
            <li>{{ $t("message.p.edit.p1") }}</li>
            <li>{{ $t("message.p.edit.p2") }}</li>
            <li>{{ $t("message.p.edit.p3") }}</li>
            <li>
                {{ $t("message.p.edit.p4") }}
                <router-link to="/help/interaction">{{ $t("message.helpinteraction") }}</router-link>
            </li>
            <li>
                {{ $t("message.p.edit.p5") }}
                <br />
                <img class="img-fluid" src="@/assets/help/modellinktable.png" />
            </li>
            <li>{{ $t("message.p.edit.p6") }}</li>
        </ol>

        <h3>{{ $t("message.h.fetch") }}</h3>
        <ol>
            <li>{{ $t("message.p.fetch.p1") }}</li>
            <li>{{ $t("message.p.fetch.p2") }}</li>
            <li>{{ $t("message.p.fetch.p3") }}</li>
            <li>{{ $t("message.p.fetch.p4") }}</li>
            <li>{{ $t("message.p.fetch.p5") }}</li>
            <li>{{ $t("message.p.fetch.p6") }}</li>
            <li>{{ $t("message.p.fetch.p7") }}</li>
        </ol>

        <h3>{{ $t("message.h.graph") }}</h3>
        <ol>
            <li>{{ $t("message.p.graph.p1") }}</li>
            <li>{{ $t("message.p.graph.p2") }}</li>
            <li>{{ $t("message.p.graph.p3") }}</li>
            <li>{{ $t("message.p.graph.p4") }}</li>
            <li>{{ $t("message.p.graph.p5") }}</li>
        </ol>
        <h3>{{ $t("message.h.bestpractice") }}</h3>
        <ul>
            <li>{{ $t("message.p.bestpractice.p1") }}</li>
            <li>{{ $t("message.p.bestpractice.p2") }}</li>
        </ul>
    </div>
</template>



<script>
export default {
    name: "HelpModellink",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Help';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Model link",
                        notes: "Notes",
                        match: "Using the model Match function in order to automatically create links",
                        edit: "Editing links or create them manually",
                        fetch: "Fetching loads",
                        graph: "Creating a graph of all links",
                        bestpractice: "Best practise",
                    },
                    a: {

                    },

                    p: {
                        notes: {
                            p1: "All RFEM model files that should be linked have to be in the same directory.",
                            p2: "Loading is always fetched from the source model. The link information is stored in the target model."
                        },
                        match: {
                            p1: "The fastest way to set up a link between two models is to copy the elements we want to link from one model to the other model and use the Match function. For now, it does not matter if you start with the source or the target model. The workflow is identical for nodes and members.",
                            p2: "Selecting the elements you want to link",
                            p3: "Copy them into the clipboard CTRL+C",
                            p4: "Switch to the other model",
                            p5: "Paste the elements CTRL-V with zero offset",
                            p6: "Now switch to the target model to setup the links",
                            p7: "Start RFEX",
                            p8: "Select tabpage Link",
                            p9: "Rescan Folder to update the list of models in the directory",
                            p10: "Select the source model you want to match",
                            p11: "Click on Match",
                            p12: "RFEX looks for members that share the same start and end node in terms of (x, y, z) coordinates and creates a link.",
                            p13: "RFEX now looks for nodes that share the same (x, y, z) coordinates and have a support in the source model (and are not part of the just matched members) and creates a link.",

                        },
                        edit: {
                            p1: "Open the target model",
                            p2: "Launch RFEX",
                            p3: "Switch to tabpage Members (or Nodes if you want to link nodes)",
                            p4: "Select the members you want to create links for as shown here: ",
                            p5: "Add a row for each link you want to define in the links table.",
                            p6: "Click on apply in order to store the links for each member",
                        },
                        fetch: {
                            p1: "Open the target model",
                            p2: "Launch RFEX",
                            p3: "Switch to tabpage Link",
                            p4: "Rescan Folder to update the list of models in the directory",
                            p5: "Select all source models you want to fetch loading from",
                            p6: "Click Fetch loads",
                            p7: "RFEX will automatically calculate the source model if the results are missing. After that the loading is imported."
                        },
                        graph: {
                            p1: "Open the target model",
                            p2: "Launch RFEX",
                            p3: "Switch to tabpage Link",
                            p4: "A click on Graph will open all linked models and recursively search for links.",
                            p5: "When this search is completed, you will see a graph, which you can export or print."
                        },
                        bestpractice: {
                            p1: "Use Import/Export to MS Excel to copy the load cases from one model to the other.",
                            p2: "Use the same parameters in all your model files in order to easily change load values. Export them to a file and import them into all your models."
                        }
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Verknüpfte Modelle",
                        notes: "Hinweise",
                        match: "Verknüpfungen automatisch erzeugen mit der Match Funktion.",

                        edit: "Verknüpfungen bearbeiten oder händisch erzeugen",
                        fetch: "Lasten holen",
                        graph: "Graph erzeugen"
                    },
                    a: {

                    },

                    p: {
                        notes: {
                            p1: "Alle RFEM Modelle, welche miteinander verknüpft werden sollen, müssen im selben Verzeichnis liegen.",
                            p2: "Die Belastung wird immer vom Quellmodell geholt, die Verknüpfungen werden im Zielmodell gespeichert."
                        },
                        match: {
                            p1: "Am schnellsten können Verknüpfungen zwischen zwei Modellen erzeugt werden, in dem die Elemente von einem Modell in das andere kopiert werden und die Match Funktion verwendet wird. Für diesen Schritt ist es egal, ob Sie mit dem Quellmodell oder mit dem Zielmodell starten. Der Workflow ist ident für Stab- und Knotenverknüpfungen.",
                            p2: "Auswählen der Elemente, welche verknüpft werden sollen",
                            p3: "Kopieren in die Zwischenablage CTRL+C",
                            p4: "Zum anderen Modell wechseln",
                            p5: "Elemente aus der Zwischenablage ohne Versatz einfügen CTRL-V",
                            p6: "Nun zum Zielmodell wechseln, um die Verknüpfungen zu erstellen",
                            p7: "RFEX starten",
                            p8: "Zum Reiter Link wechseln ",
                            p9: "Rescan Folder anklicken, um die Liste der Modelle im Verzeichnis einzulesen",
                            p10: "Alle Zielmodelle auswählen, die verknüpft werden sollen",
                            p11: "Match anklicken",
                            p12: "RFEX sucht nun nach Stäben, welche in beiden Modellen idente Anfangs- und Endpunkte haben. Der Vergleich erfolgt nicht über die Knotennummer, sondern über die Koordinaten (x, y, z). Bei Übereinstimmung wird eine Stab Verknüpfung erzeugt.",
                            p13: "Danach sucht RFEX Knoten mit gleichen (x, y, z) Koordinaten, welche im Quellmodell gelagert sind (und nicht Teil eines soeben verknüpften Stabes sind) und erzeugt eine Knotenverknüpfung.",

                        },
                        edit: {
                            p1: "Das Zielmodell öffnen",
                            p2: "RFEX starten",
                            p3: "Zum Reiter Members (oder Nodes) wechseln",
                            p4: "Die Stäbe auswählen, die verknüpft werden sollen, wie hier gezeigt: ",
                            p5: "Für jede Verknüpfung eine Zeile in der Tabelle anlegen",
                            p6: "Auf apply klicken um Änderungen zu speichern.",
                        },
                        fetch: {
                            p1: "Das Zielmodell öffnen",
                            p2: "RFEX starten",
                            p3: "Zum Reiter Link wechseln",
                            p4: "Rescan Folder anklicken, um die Liste der Modell im Verzeichnis einzulesen",
                            p5: "Alle Quellmodelle auswählen, von denen die Lasten geholt werden sollen",
                            p6: "Fetch loads anklicken",
                            p7: "Falls keine Ergebnisse im Zielmodell vorliegen, startet RFEX automatisch dessen Berechnung und importiert danach die Lasten"
                        },
                        graph: {
                            p1: "Das Zielmodell öffnen",
                            p2: "RFEX starten",
                            p3: "Zum Reiter Link wechseln",
                            p4: "Graph anklicken. RFEX öffnet nun rekursiv alle verknüpften Modelle und sucht nach Verknüpfungen.",
                            p5: "Sobald die Suche abgeschlossen ist, öffnet sich ein Fenster mit dem Graph. Dieser kann bearbeitet, gespeichert und exportiert werden."
                        },
                        bestpractice: {
                            p1: "Lastfälle können mittels Import/Export von/nach MS Excel zwischen Modellen kopiert werden.",
                            p2: "Die Verwendung von Parametern für z.B. Lastwerte bietet sich bei der Arbeit mit mehreren Dateien an. RFEM kann die Parameterliste in eine Datei exportieren, diese Datei kann dann in alle anderen Modellen importiert werden."

                        }
                    }
                }
            },
        }
    }
};
</script>