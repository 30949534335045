<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.connect") }}</h3>
        <ol>
            <li>{{ $t("message.p.step1") }}</li>
            <li>{{ $t("message.p.step2") }}</li>
            <li>{{ $t("message.p.step3") }}</li>
            <li>
                {{ $t("message.p.step4") }}
                <br />
                <img class="img-fluid img-thumbnail" src="@/assets/help/titlebar.png" />
            </li>
            <li>{{ $t("message.p.step5") }}</li>
        </ol>

        <h3>{{ $t("message.h.select") }}</h3>
        <p>{{ $t("message.p.select.p") }}</p>
        <img class="img-fluid img-thumbnail" src="@/assets/help/select.png" />
        <ul>
            <li>
                <b>Pick</b>
                {{ $t("message.p.select.pick") }}
            </li>
            <li>
                <b>All</b>
                {{ $t("message.p.select.all") }}
            </li>
            <li>
                <b>Swap</b>
                {{ $t("message.p.select.swap") }}
            </li>
            <li>
                <b>Select</b>
                {{ $t("message.p.select.select") }}
                <br />
                <img class="img-fluid img-thumbnail" src="@/assets/help/selectmanual.png" />
            </li>
        </ul>

        <h3>{{ $t("message.h.properties") }}</h3>
        <ol>
            <li>{{ $t("message.p.properties.step1") }}</li>
            <li>
                {{ $t("message.p.properties.step2") }}
                <br />
                <img class="img-fluid img-thumbnail" src="@/assets/help/property.png" />
            </li>
            <li>{{ $t("message.p.properties.step3") }}</li>
        </ol>

        <h3>{{ $t("message.h.unlocker") }}</h3>
        <p>{{ $t("message.p.unlocker") }}</p>
    
            <h3>{{ $t("message.h.filelock") }}</h3>
        <p>{{ $t("message.p.filelock") }}</p>
    </div>
</template>



<script>
export default {
    name: "HelpInteraction",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Help';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Interaction with RFEM",
                        connect: "Start RFEX and connect to a model file",
                        select: "Select elements",
                        properties: "Change properties of elements",
                        unlocker: "RFEX Unlocker",
                        filelock: "File blocked",
                    },
                    a: {

                    },

                    p: {
                        step1: "Start RFEM",
                        step2: "Open a model or create a new one",
                        step3: "Launch RFEX from your start menu.",
                        step4: "The RFEX window is shown and is now attached to the active model (example.rf5 in the screenshot).",
                        step5: "You can have the RFEX window open and continue to work in RFEM as usual.",

                        select: {

                            p: "Before working on elements like nodes or members you have to tell RFEX which elements to use. The select box gives you several options:",
                            pick: "Pick the elements using the mouse.",
                            all: "Take all elements of this kind.",
                            swap: "Inverse the current selection",
                            select: "Enter the object numbers in the textbox and click on select"
                        },
                        properties: {
                            step1: "Select elements as shown above",
                            step2: "Change the property in the form. Note: Properties are only saved, when the tickbox at the beginning of this row is ticked.",
                            step3: "Click on Apply button at the bottom to save changes."
                        },
                        unlocker: "During RF-COM operations the user interface of RFEM is locked. In case of a RFEX crash or an error in the COM communication RFEM remains locked. To unlock RFEM you can run RFEX Unlocker from your start menu and continue to work and save your file.",
                        filelock: "In rare cases RFEM will continue to run in the background after closing its window. If you cannot open a file you have recently opened because it is still blocked, please open the task manager and search for RFEM. Stop the process and retry."
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Interaktion mit RFEM",
                        connect: "RFEX starten und mit einem Modell verbinden",
                        select: "Elemente auswählen",
                        properties: "Eigenschaften von Elementen ändern",
                        filelock: "Datei gesperrt",
                    },
                    a: {

                    },

                    p: {
                        step1: "RFEM starten",
                        step2: "Ein bestehendes Modell öffnen oder ein neues Modell erzeugen",
                        step3: "RFEX über das Startmenü starten",
                        step4: "Das RFEX Fenster wird angezeigt und ist nun verbunden mit dem aktuellen Modell (example.rf5 im Screenshot).",
                        step5: "Es ist möglich, das RFEX Fenster geöffnet zu lassen und weiter wie gehabt in RFEM zu arbeiten.",

                        select: {

                            p: "Mit dieser Auswahlbox kann RFEX mitgeteilt werden, welche Objekte im nächsten Schritt bearbeitet werden sollen.",
                            pick: "Objekte mit der Maus auswählen",
                            all: "Alle Objekte auswählen",
                            swap: "Aktuelle Auswahl invertieren",
                            select: "Manuelle Eingabe der Objektnummern in der Textbox und abschließend auf Select klicken."
                        },
                        properties: {
                            step1: "Objekte wie oben dargestellt auswählen",
                            step2: "Ändern der Eigenschaft im Formular. Änderungen werden nur für die Zeilen übernommen, in denen die Checkbox ganz links angeklickt wurde.",
                            step3: "Klick auf Apply am Ende des Formulars um Änderungen zu speichern."
                        },
                        unlocker: "Während RF-COM Operationen bleibt die RFEM Oberfläche gesperrt. Sollte RFEX abstürzen oder ein Fehler in der COM Kommunikation auftreten, kann es vorkommen, dass RFEM gesperrt bleibt. Um RFEM wieder zu entsperren, bitte den RFEX Unlocker aus dem Startmenü starten. Danach kann mit der Datei weiter gearbeitet werden.",
                        filelock: "In seltenen Fällen läuft der RFEM Prozess im Hintergrund weiter, wenn man das RFEM Fenster schließt. Wenn eine Datei nicht geöffnet werden kann, weil diese blockiert ist, dann bitte den Task Manager starten. Den noch laufenden RFEM Prozess suchen und beenden."
                    }
                }
            },
        }
    }
};
</script>