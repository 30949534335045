<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>
        <p>{{ $t("message.p.summary") }}</p>

        <h3>{{ $t("message.h.concept") }}</h3>

        <p>
            {{ $t("message.p.concept.first") }}
            <router-link to="/features/modellink">{{ $t("message.a.modellink") }}</router-link>
            {{ $t("message.p.concept.second") }}
        </p>

        <h3>{{ $t("message.h.why") }}</h3>
        <p>{{ $t("message.p.why") }}</p>

        <h3>{{ $t("message.h.example") }}</h3>
        <p>{{ $t("message.p.example.p1") }}</p>
        <p><img class="img-fluid w-75" src="@/assets/substructures.png" /></p>
        <p>{{ $t("message.p.example.p2") }}</p>
        <p><img class="img-fluid w-50" src="@/assets/substructurescs.png" /></p>
        <p>{{ $t("message.p.example.p3") }}</p>
        <p><img class="img-fluid w-100" src="@/assets/substructureslinks.png" /></p>
        <p>{{ $t("message.p.example.p4") }}</p>
        
        <p><img class="img-fluid w-100" src="@/assets/substructuresdou.png" /></p>


        <h3>{{ $t("message.h.faq") }}</h3>
        <ul class="list-group">
            <li class="list-group-item">
                <b>{{ $t("message.p.advantages.change.q") }}</b>
                <br />
                {{ $t("message.p.advantages.change.a") }}
            </li>
            <li class="list-group-item">
                <b>{{ $t("message.p.advantages.suspended.q") }}</b>
                <br />
                {{ $t("message.p.advantages.suspended.a") }}
            </li>
        </ul>
        <!--
        <ol>
            <li>Instead of copying the truss all over the place, the truss is only defined once in a seperate rfem model file.</li>
            <li>In the main model the truss is substituted by a single member.</li>
            <li>This single member is copied to all positions, where this truss should be used.</li>
            <li>The two models have to be linked as shown here.</li>
            <li>Apply loading on the main model.</li>
            <li>
                We are now ready to run - Click on
                <b>Push loads</b> will do these steps for all the placeholders:
                <ol>
                    <li>Transfer the loading from the single member from the main model to the truss model.</li>
                    <li>Calculate the truss and get the maximum degree of utilisation</li>
                </ol>
            </li>
            <li>For each truss the maximum degree of utilisation is shown in the main model.</li>
        </ol>

        -->
    </div>
</template>



<script>
export default {
    name: "FeatSubstructures",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - ';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Substructures",
                        concept: "Main idea",
                        why: "Why?",
                        example: "Example",
                        faq: "FAQ",
                    },
                    a: {
                        modellink: "Model link",
                    },

                    p: {
                        summary: "A Solution for repetitive substructures  in a model in order to redundancy and speed up calculation.",
                        concept: {
                            first: "Model repetitive parts of a structure (like trusses) only once and insert that substructure into another model as a single member as many times as you want. Please note that substructures are implemented by using model links with a changing data source, so please have a look at the topic ",
                            second: " first to be familiar with that concept."
                        },
                        why: "As an engineer you normally try to build up a complex building out of as few different parts as possible. Reusing elements like trusses simplifies design and reduces overall production costs. But a model containing multiple copies of the same substructures is slow, error prone, cumbersome to change and difficult to document.",
                        example: {
                            p1: "The supporting structure consists of different truss types: T100, T200 and T210.",
                            p2: "In the main model, where all the loading is applied, a numerical cross section is defined for every truss type. For every truss only one member with one of these cross sections is modelled.",
                            p3: "A seperate RFEM model is created for each truss type and given a name that corresponds exactly to the previously defined cross section. In this model a link is defined between the upper chord member and one of the members in the main model of this truss type.",
                            p4: "RFEX is now able to transfer the loading of each member of this truss type one by one to the truss model, calculate the internal forces and run the steel check. The resulting maximum degree of utilisation is then shown in the main model as a graph."
                        },
                        advantages: {
                            change: {
                                q: "How can I change a truss type in the main model?",
                                a: "Just change the cross section type of the member representing the truss."
                            },
                            suspended: {
                                q: "Is it possible to define different loads on top and bottom chord?",
                                a: "Yes. The model link supports the filtering of load cases. Therefore you have to define two links; one for the top and one for the bottom chord. Then you just need to provide a list of load cases to be used for each of these two links."
                            },

                        }

                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Substrukturen",
                        concept: "Prinzip",
                        why: "Warum?",
                        example: "Beispiel",
                    },
                    a: {
                        modellink: "Verknüpfte Modelle",
                    },

                    p: {
                        summary: "Herauslösen von Substrukturen zur Vermeidung von Redundanz und zur Reduktion der Rechenzeit.",
                        concept: {
                            first: "Sich wiederholende Substrukturen (wie Fachwerke) werden in einem eigenen Modell nur ein einziges Mal modelliert. Dieses Element kann dann in anderen Modell beliebig oft mit Hilfe eines einzelnen Ersatzstabes eingefügt werden. Substrukturen basieren auf verknüpften Modellen mit variablen Datenquellen, die Grundlagen sind unter dem Punkt ",
                            second: "beschreiben.",
                        },
                        why: "Als Ingenieur versucht man normalerweise, eine Tragstruktur aus möglichst wenigen Gleichteilen aufzubauen. Die mehrfache Verwendung von Teilstrukturen vereinfacht die Planung und reduziert die Gesamtkosten. Ein Modell, welches mehrere Kopien der gleichen Substrukturen enthält, ist jedoch langsam, fehleranfällig, mühsam zu ändern und schwierig zu dokumentieren.",
                        example: {
                            p1: "Die Tragstruktur besteht aus drei Fachwerktypen: T100, T200 und T210.",
                            p2: "Im Hauptmodell, wo die gesamte Belastung aufgebracht wird, ist für jeden Fachwerktyp ein numerischer Ersatzquerschnitt definiert. Jedes Fachwerk wird durch einen einzigen Ersatzstab mit einem dieser Querschnitte modelliert.",
                            p3: "Für jeden Fachwerktyp wird ein separates RFEM Modell angelegt, dessen Dateiname exakt dem Namen des zugehörigen Ersatzquerschnittes entspricht. In diesem Modell ist die Verknüpfung mit einem Stab des Hauptmodells definiert.",
                            p4: "RFEX ist nun in der Lage, die Belastung aus dem Hauptmodell für jeden Ersatzstab eines Fachwerktyps nach der Reihe an das Fachwerkmodell zu übergeben, die Schnittgrößen und den EC3 Nachweis zu berechnen. Der maximale Auslastungsgrad wird an das Hauptmodell übergeben und dort grafisch dargestelllt."
                        },
                        advantages: {
                            change: {
                                q: "Wie kann im Hauptmodell ein Fachwerktyp geändert werden?",
                                a: "Durch Änderung des Querschnitts des Ersatzstabs."
                            },
                            suspended: {
                                q: "Können unterschiedliche Lasten am Ober- und Untergurt definiert werden?",
                                a: "Ja. Verknüpfte Modelle unterstützen das Filtern nach Lastfallnummern. Im Fachwerkmodell werden zwei Verknüpfungen angelegt, einmal für den Ober- und einmal für den Untergurt. Bei jeder Verknüpfung ist dann nur noch eine Liste der Lastfallnummer zu definieren, für welche Lastfälle diese Verknüpfung aktiv sein soll."
                            },

                        }
                    }
                }
            },
        }
    }
};
</script>