import authHeader from './auth-header';
import http from '../plugins/http';

class UserService {
  download5() {
    return http.get('download5/', { headers: authHeader(), responseType: 'blob' });
  }

  addLicense(license) {
    return http.post('licenses/', license, { headers: authHeader() })
  }


  getLicenses() {
    return http.get('licenses/', { headers: authHeader() });
  }
  deleteLicense(pk) {
    return http.delete('licenses/' + pk, { headers: authHeader() });
  }


  getAdminBoard() {
    return http.get('admin', { headers: authHeader() });
  }
}

export default new UserService();
