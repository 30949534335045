<template>
    <div class="col-md-12">
        <div class="card card-container">
            <h2>{{ $t("message.add") }}</h2>
            <Form @submit="createLicense" :validation-schema="schema">
                <div class="form-group">
                    <label for="email">{{ $t("message.email") }}</label>
                    <Field name="email" type="text" class="form-control" />
                    <ErrorMessage name="email" class="error-feedback" />
                </div>
                <div class="form-group">
                    <label for="pwd">{{ $t("message.password") }}</label>
                    <Field name="pwd" type="text" class="form-control" />
                    <ErrorMessage name="pwd" class="error-feedback" />
                </div>

                <div class="form-group">
                    <button class="btn btn-primary btn-block" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>{{ $t("message.add") }}</span>
                    </button>
                </div>

                <div class="form-group">
                    <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";

export default {
    name: "LicenseForm",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required(this.$t("message.error.email.required")),
            pwd: yup.string().required(this.$t("message.error.password.required")),
        });

        return {
            loading: false,
            message: "",
            schema,
        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Add License';
    },
    methods: {
        createLicense(license) {
            UserService.addLicense(license).then(
                () => {
                    this.$router.push("/licenses");
                },
                (error) => {
                    this.loading = false;

                    this.message =
                        (error.response &&
                            error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    "email": "Email",
                    "password": "Password",
                    "add": "Add License",
                    "error": {
                        "email":
                        {
                            "required": "Email is required!"
                        },
                        "password": {
                            "required": "Password is required!"
                        },
                    }
                }
            },
            de: {
                message: {
                    "email": "E-Mail",
                    "password": "Passwort",
                    "add": "Lizenz hinzufügen",
                    "error": {
                        "email":
                        {
                            "required": "Bitte eine E-Mail Adresse eingeben!"
                        },
                        "password": {
                            "required": "Bitte ein Passwort eingeben!"
                        },
                    }

                }
            }
        },

    }

};
</script>

<style scoped>
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.error-feedback {
    color: red;
}
</style>
