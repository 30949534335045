<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>
        <p>{{ $t("message.p.summary") }}</p>

        <h3>{{ $t("message.h.concept") }}</h3>
        <p>{{ $t("message.p.concept") }}</p>
                <h3>{{ $t("message.h.example") }}</h3>
        <p>{{ $t("message.p.example") }}</p>
 <img class="img-fluid w-100" src="@/assets/help/bucklesystem.png" />

        
    </div>
</template>



<script>
export default {
    name: "FeatEc3",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - ';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "EC3",
                        concept: "Main idea",
                        example: "Example",
                    },
                    a: {
                    },

                    p: {
                        summary:"RFEM5 has a major design flaw. If you change data in RFEM, all modules like the EC3 module are not aware of the changes. In case you renumber a member all the information about buckling length, lateral support and so on will be lost.",
                        concept: "The buckling information is stored directly in RFEM using extra information on nodes of the type nodes on line. The buckling length and intermediate lateral supports are rewritten in every EC3 module case any time you want. In addition RFEX creates comments on every node with support so you can visually check your model for missing or wrong support definitions. As all information is stored within the nodes, this buckling information does not get lost, even if you copy a member.",
                        example: "In this simple example the column is a single member with three nodes on lines (one in every quarter point). All of them are set as supported in Z, whereas the middle one is also supported in Y. Buckling coefficients kcr,y and kcr,z are automatically calculated and set in every module case where this member is checked. Please note the comments in RFEM showing the type of constraint set on every node. Instead of modifying the buckling coefficients you can also set Intermediate Lateral Restraints."
                    }
                }
            },
            de: {
                 message: {
                    h: {
                        title: "EC3",
                        concept: "Prinzip",
                        example: "Beispiel",
                    },
                    a: {
                    },

                    p: {
                        summary:"RFEM5 hat eine grundlegende Schwäche im Datenmanagement der Zusatzmodule. Die Module werden (mit wenig Ausnahmen) nicht über Änderungen im Modell informiert. Zahlreiche Operationen wie Stab teilen, umnummerieren usw führen dazu, dass die Knickkonfiguration im EC3 Modul verloren geht.",
                        concept: "Die Knickinformationen werden direkt in RFEM und nicht im Zusatzmodul gespeichert. Diese werden in den Knoten vom Typ Knoten auf Linie gespeichert. Knicklängen oder seitliche Festhaltungen (Intermediate Lateral Restraints) werden jedes Mal neu in alle EC3 Fälle geschrieben, nachdem am Modell Änderungen durchgeführt wurden. Zusätzlich erzeugt RFEX Kommentare bei jedem Knoten, sodass die Knickkonfiguration graphisch kontrolliert werden kann. Da die Knickdaten in den Knoten gespeichert sind, bleibt diese Information erhalten, selbst wenn der Träger weiter kopiert wird.",
                        example: "In diesem einfachen Beispiel besteht die Stütze aus einem einzigen Stab mit drei Knoten vom Typ Knoten auf Linie (in den Viertelspunkten). Alle Knoten sind als unverschieblich in Z, der mittlere zusätzlich unverschieblich in Y definiert. Die Knickbeiwerte kcr,y und kcr,z werden automatisch berechnet und in allen EC3 Fällen eingetragen, in denen dieser Stab berechnet wird. Die Kommentare [y] bzw (yz) zeigen grafisch die Knickkonfiguration an. Anstelle der Modifikation der Knickbeiwerte können auch seitliche Festhaltungen definiert werden."
                    }
                }
            }
        }
    }
};
</script>