import http from '../plugins/http'

class AuthService {
  login(user) {

    return http
      .post('api/auth/token/obtain/', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('refresh_token', response.data.refresh);
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('access_token');
  }


}

export default new AuthService();
