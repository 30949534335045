<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>
        <div class="alert alert-primary" role="alert">
           This webpage is work in progress. Expect to find new information soon.
        </div>

        <div class="alert alert-warning" role="alert">
            {{ $t("message.p.disclaimer") }}
            <a href="https://www.dlubal.com/" target="_blank">Dlubal</a>.
        </div>

        <h3>{{ $t("message.h.what") }}</h3>
        <p>{{ $t("message.p.description") }}</p>
        <ul class="list-group">
            <li class="list-group-item">
                <font-awesome-icon icon="link" />&nbsp;
                <b><router-link to="/features/modellink">{{ $t("message.a.modellink") }}</router-link></b>
                <br> {{ $t("message.p.modellink") }}
            </li>
            <li class="list-group-item">
                <font-awesome-icon icon="cubes" />&nbsp;
                <b><router-link to="/features/substructures">{{ $t("message.a.substructures") }}</router-link></b>
                <br> {{ $t("message.p.substructures") }}
            </li>
            <li class="list-group-item">
                <font-awesome-icon icon="check" />&nbsp;
                <b><router-link to="/features/ec3"> {{ $t("message.a.ec3") }}</router-link></b>
                <br>{{ $t("message.p.ec3") }}
            </li>
            <li class="list-group-item">
                <font-awesome-icon icon="building" />&nbsp;
                <b><router-link to="/features/bim">{{ $t("message.a.bim") }}</router-link></b>
                <br> {{ $t("message.p.bim") }}
                <a
                    href="https://www.tekla.com/"
                    target="_blank"
                >Tekla Stuctures</a>
            </li>
            <li class="list-group-item">
                <font-awesome-icon icon="pen" />&nbsp;
                <b><router-link to="/features/modelling">{{ $t("message.a.modelling") }}</router-link></b>
                <br> {{ $t("message.p.modelling") }}
            </li>
        </ul>
        <p></p>

        <h3>{{ $t("message.h.how") }}</h3>
        <p>{{ $t("message.p.how") }}</p>

        <h3>{{ $t("message.h.rfem6") }}</h3>
        <p>{{ $t("message.p.rfem6") }}</p>

        <h3>{{ $t("message.h.prize") }}</h3>
        <p>
            {{ $t("message.p.prize") }}
            <a href="mailto:info@ztto.at" target="_blank">info@ztto.at</a>
        </p>

        <h3>{{ $t("message.h.about") }}</h3>
        <p>
            {{ $t("message.p.about") }}
            <a href="https://www.ztto.at" target="_blank">www.ztto.at</a>
        </p>
    </div>
</template>



<script>


export default {

    name: "Home",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Home';
    },
    mounted() {

    },
    methods: {
    },

    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "RFEX - Extensions for Dlubal RFEM 5",
                        what: "What is RFEX?",
                        how: "How does RFEX work?",
                        rfem6: "What about RFEM6?",
                        prize: "How much is it?",
                        about: "Who am I?",
                    },
                    a: {
                        modellink: "Model link",
                        substructures: "Substructures",
                        ec3: "EC3",
                        bim: "BIM",
                        modelling: "Modelling tools",
                    },

                    p: {
                        disclaimer: "Disclaimer: This project is neither affiliated directly with nor endorsed by",
                        description: "A set of tools to boost your RFEM workflow to the next level:",
                        modellink: "Transfer loads between different RFEM models by a single click",
                        substructures: "Reuse simple models (like plain trusses) to build up the complete building",
                        ec3: " Data handling improved",
                        bim: "Take control of the link to ",
                        modelling: "Convert node types, sets of members...",
                        how: "You need a licence for RFEM5 and the module RF-COM from Dlubal. Open a model in RFEM and start RFEX to interact with the tools. All data is stored inside the RFEM models, but the files stay fully compatible with RFEM.",
                        rfem6: "With the release of RFEM6 the API has completely changed. As there are some data structures still missing in the API, RFEX has not been finished for RFEM6. But we are optimistic that this will happen soon.",
                        prize: "Please contact us at",
                        about: "A civil engineer (focused on steel constructions) with a strong passion for coding. More information here:",
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "RFEX - Erweiterungen für Dlubal RFEM 5",
                        what: "Was ist RFEX?",
                        how: "Wie funktioniert RFEX?",
                        rfem6: "RFEM6 Unterstützung",
                        prize: "Wieviel kostet RFEX?",
                        about: "Wer steckt hinter RFEX?",
                    },
                    a: {
                        modellink: "Verknüpfte Modelle",
                        substructures: "Substrukturen",
                        ec3: "EC3",
                        bim: "BIM",
                        modelling: "Modellierungstools",
                    },
                    p: {
                        disclaimer: "Hinweis: Dieses Projekt ist unabhängig und nicht verbunden mit",
                        description: "Eine Sammlung von Tools, um Ihre Arbeit mit RFEM zu revolutionieren:",
                        modellink: "Übertragen von Lasten zwischen Modellen auf Knopfdruck",
                        substructures: "Effiziente Berechnung von sich wiederholenden Positionen (wie Fachwerken) durch Herauslösen aus dem Gesamttragwerk",
                        ec3: "Definition der seitlichen Halterungen bereits in RFEM",
                        bim: "Volle Kontrolle über den BIM-Link zu ",
                        modelling: "Konvertierung von Knotentypen, Stabsätzen...",
                        how: "Sie benötigen eine Lizenz für RFEM5 und das Modul RF-COM von Dlubal. Nach dem Öffnen eines Modells in RFEM5 starten Sie RFEX und können damit alle Tools steuern. Alle Daten werden direkt in den RFEM Modellen gespeichert, die Modelle selbst bleiben natürlich völlig kompatibel mit RFEM.",
                        rfem6: "Mit der Veröffentlichung von RFEM6 hat sich nicht nur die Schnittstelle, sondern auch die API völlig geändert. Nachdem noch einige Komponenten in der API fehlen, ist RFEX noch nicht für RFEM6 verfügbar. Aber wir sind optimistisch, dass dies demnächst der Fall sein wird.",
                        prize: "Bitte nehmen Sie Kontakt auf unter",
                        about: "Ein Ziviltechniker (Schwerpunkt Tragwerksplanung Stahlbau) und Software Entwickler, mehr Informationen hier:",
                    }
                }
            }
        }
    }
};
</script>