<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>
        <p>{{ $t("message.p.summary") }}</p>

        <h3>{{ $t("message.h.concept") }}</h3>
        <p>{{ $t("message.p.concept") }}</p>

        <h3>{{ $t("message.h.why") }}</h3>
        <p>
            {{ $t("message.p.why") }}
            <a
                href="https://www.ztto.at/#menu_top"
                target="_blank"
            >www.ztto.at</a>
        </p>

        <h3>{{ $t("message.h.example") }}</h3>
        <div v-html="$t('message.p.example')"></div>
        <img class="img-fluid w-100" src="@/assets/modellink.png" />
        <h3>{{ $t("message.h.faq") }}</h3>
        <ul class="list-group">
            <li class="list-group-item">
                <b>{{ $t("message.p.advantages.general.q") }}</b>
                <br />
                {{ $t("message.p.advantages.general.a") }}
            </li>
            <li class="list-group-item">
                <b>{{ $t("message.p.advantages.update.q") }}</b>
                <br />
                {{ $t("message.p.advantages.update.a") }}
            </li>
            <li class="list-group-item">
               <b> {{ $t("message.p.advantages.match.q") }}</b>
                <br />
                {{ $t("message.p.advantages.match.a") }}
            </li>
            <li class="list-group-item">
               <b> {{ $t("message.p.advantages.multiple.q") }}</b>
                <br />
                {{ $t("message.p.advantages.multiple.a") }}
            </li>
            <li class="list-group-item">
               <b> {{ $t("message.p.advantages.loadcase.q") }}</b>
                <br />
                {{ $t("message.p.advantages.loadcase.a") }}
            </li>
            <li class="list-group-item">
              <b>  {{ $t("message.p.advantages.traceability.q") }}</b>
                <br />
                {{ $t("message.p.advantages.traceability.a") }}
            </li>
        </ul>
    </div>
</template>



<script>
export default {
    name: "FeatModellink",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Model link';
    },
    mounted() {

    },
    methods: {

    },

    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Model link",
                        concept: "Main idea",
                        why: "Why?",
                        example: "Example",
                        faq: "FAQ",
                        links: "Define links",
                    },
                    a: {
                    },

                    p: {
                        summary: "Transferring loads between different RFEM models by a single click.",
                        concept: "To transfer load from one model (the source) to another model (the target) we define links between elements of these models. RFEX is then able to read the results in the source model and create (or update) the loading for each load case in the target model. You can link nodes to create point loads from support reactions. It is also possible to link members to create member loads.",
                        why: "Splitting the fem model of a supporting structure in many smaller elements has huge benefits over working on a single 3D model containing all elements. For an in-depth comparison of these two approaches please have a look at: ",
                        example: "There are three rfem models linked in this example. Model <i>platform.rf5</i> and <i>layer12m.rf5</i> are linked using node links between the column footings and nodes on the two (black) secondary beams where this little platform is located. Model <i>layer12.rf5</i> is linked with the main model <i>plant.rf5</i> using member links between the turquoise main members of both files. RFEX creates the pink point loads in <i>layer12m.rf5</i> based on the support reactions of <i>platform.rf5</i>. It will also create the blue point and member loads in plant.rf5 based on the results of <i>layer12m.rf5</i>.",
                        advantages: {
                            general: {
                                q: "Which kind of loading is supported?",
                                a: "All. RFEX works with all kinds of loading by design."
                            },
                            update: {
                                q: "The source model has changed? ",
                                a: "Just click on fetch loads and all loading is updated."
                            },
                            match: {
                                q: "Setting up the links sounds time consuming? ",
                                a: "There is a match function which automatically establishes links between models for all elements with the same position in space."
                            },
                            multiple: {
                                q: "More than two files? ",
                                a: "You can link as many models as you want. RFEX will draw a flow diagram so you can actually see how all the models are connected with each other."
                            },
                            loadcase: {
                                q: "What about different load cases? ",
                                a: "RFEX will try to fetch loads for every load case that exists in the source model. These load cases must exist in the target model. Of course the target model may contain other load cases."
                            },
                            traceability: {
                                q: "How do I know where a generated load comes from? ",
                                a: "RFEX adds a comment to every load showing the data source."
                            },
                        },
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Verknüpfte Modelle",
                        concept: "Prinzip",
                        why: "Warum?",
                        example: "Beispiel",
                        links: "Verknüpfungen herstellen",
                    },
                    a: {
                    },

                    p: {
                        summary: "Automatisierter Lastübertrag zwischen verschiedenen RFEM Modellen auf Knopfdruck.",
                        concept: "Für den Lastübertrag von einem Modell (Quelle) auf ein anderes Modell (Ziel) werden Verknüpfungen zwischen Elementen dieser Modelle definiert. RFEX liest die Ergebnisse im Quellmodell und erzeugt (oder aktualisiert) im Zielmodell für jeden Lastfall die zugehörige Belastung. Es können Knoten miteinander verknüpft werden um aus Auflagerreaktionen Punktlasten zu erzeugen. Weiters können Stäbe verknüpft werden, um Stablasten zu erzeugen.",
                        why: "Die Zerlegung einer statischen Berechnung in mehrere Teilmodelle bringt zahlreiche Vorteile gegenüber einem einzigen 3D Modell, in dem alle Elemente vorhanden sind. Eine detaillierte Gegenüberstellung dieser beiden Modellierungsmöglichkeiten finden Sie hier: ",
                        example: "In diesem Beispiel sind drei RFEM Modelle miteinander verknüpft. Modell <i>platform.rf5</i> und <i>layer12m.rf5</i> sind verbunden über Knotenverknüpfung zwischen den Stützenfußpunkten der kleinen Plattform und den Knoten auf den Trägern, wo diese Plattform aufgeständert ist. Das Modell <i>layer12.rf5</i> ist mit dem Hauptmodell <i>plant.rf5</i> über Stabverknüpfungen zwischen den türkisen Hauptträgern dieser Bühnenebene verbunden. RFEX erzeugt die pinken Einzellasten im Modell <i>layer12.rf5</i> auf Basis der Auflagerreaktionen von <i>platform.rf5</i>. Auf Basis der Ergebnisse in <i>layer12m.rf5</i> werden die blauen Einzel- und Stablasten im Hauptmodell <i>plant.rf5</i> erzeugt. ",
                        advantages: {
                            general: {
                                q: "Welche Lastarten werden unterstützt? ",
                                a: "Alle - RFEX funktioniert mit allen Arten von Belastungen."
                            },
                            update: {
                                q: "Das Quellmodell wurde geändert? ",
                                a: "Mit einem Klick wird in ein paar Sekunden die gesamte Belastung aktualisiert."
                            },
                            match: {
                                q: "Dauert das Anlegen der Verknüpfungen lange? ",
                                a: "Mit Hilfe der Match Funktion können Verknüpfungen zwischen Modellen automatisch erzeugt werden: Elemente mit gleicher Lage im Raum werden automatisch miteinander verknüpft."
                            },
                            multiple: {
                                q: "Wieviele Modelle können miteinander verknüpft werden? ",
                                a: "Es können beliebig viele Modelle verknüpft werden. RFEX kann ein Flussdiagramm generieren, in dem alle Verknüpfungen zwischen den Modellen graphisch dargestellt werden."
                            },
                            loadcase: {
                                q: "Müssen die Lastfalldefinitionen in den Modellen übereinstimmen? ",
                                a: "RFEX generiert im Zielmodell eine Belastung für jeden Lastfall, der im Quellmodell existiert. Diese Lastfälle müssen somit im Zielmodell vorhanden sein. Natürlich können im Zielmodell weitere Lastfälle angelegt sein."
                            },
                            traceability: {
                                q: "Nachvollziehbarkeit der generierten Belastung? ",
                                a: "RFEX gibt im Kommentarfeld der generierten Last an, aus welcher Datei und von welchem Element dieses Belastung stammt."
                            },
                        },
                    }
                }
            },
        }
    }
};
</script>