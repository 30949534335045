import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
import Licenses from "./components/Licenses.vue";
import LicenseForm from "./components/LicenseForm.vue";
import Home from "./components/Home.vue"
import Download from "./components/Download.vue"
import FeatBim from "./components/features/Bim.vue"
import FeatEc3 from "./components/features/Ec3.vue"
import FeatModelling from "./components/features/Modelling.vue"
import FeatModellink from "./components/features/Modellink.vue"
import FeatSubstructures from "./components/features/Substructures.vue"

import HelpInteraction from "./components/help/Interaction.vue"
import HelpModellink  from "./components/help/Modellink.vue"
import HelpSubstructures from "./components/help/Substructures.vue"
import HelpEc3 from "./components/help/Ec3.vue"
import HelpModelling from "./components/help/Modelling.vue"


const routes = [
  // public
  {
    path: "/",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/features/modellink",
    component: FeatModellink,
  },
  {
    path: "/features/substructures",
    component: FeatSubstructures,
  },
  {
    path: "/features/ec3",
    component: FeatEc3,
  },
  {
    path: "/features/bim",
    component: FeatBim,
  },
  {
    path: "/features/modelling",
    component: FeatModelling,
  },
  {
    path: "/help/interaction",
    component: HelpInteraction,
  },
  {
    path: "/help/modellink",
    component: HelpModellink,
  },
  {
    path: "/help/ec3",
    component: HelpEc3,
  },
  


  {
    path: "/help/substructures",
    component: HelpSubstructures,
  },

  {
    path: "/help/modelling",
    component: HelpModelling,
  },
  // protected
  {
    path: "/download",
    component: Download,
  },
  {
    path: "/licenses",
    component: Licenses,
  },
  {
    path: "/licenses/add",
    component: LicenseForm,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/login',
    "/features/modellink",
    "/features/substructures",
    "/features/ec3",
    "/features/bim",
    "/features/modelling",
    "/help/interaction",
    "/help/modellink",
    "/help/substructures",
    "/help/ec3",
    "/help/modelling",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('access_token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;