<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>

        <h3>{{ $t("message.h.admin") }}</h3>
        <ol class="list-group list-group-numbered">
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.admin.p1.q") }}</b>
                    <br />
                    {{ $t("message.p.admin.p1.a") }}
                    <router-link to="/licenses">
                        <font-awesome-icon icon="key" />
                        {{ $t("message.a.licenses") }}
                    </router-link>
                </div>
            </li>

            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.admin.p2.q") }}</b>
                    <br />
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="Download()"
                    >{{ $t("message.a.download") }}</button>
                </div>
            </li>
        </ol>

        <h3>{{ $t("message.h.user") }}</h3>

        <ol class="list-group list-group-numbered">
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.user.p1.q") }}</b>
                    <br />
                    {{ $t("message.p.user.p1.a") }}
                </div>
            </li>
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.user.p2.q") }}</b>
                    <br />
                    {{ $t("message.p.user.p2.a") }} 
                    <i>RFEX5Setup.msi</i>
                </div>
            </li>
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.user.p3.q") }}</b>
                    <br />
                    {{ $t("message.p.user.p3.a") }}
                </div>
            </li>
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.user.p4.q") }}</b>
                    <br />
                    {{ $t("message.p.user.p4.a") }}
                </div>
            </li>
            <li class="list-group-item">
                <div class="ms-2 me-auto">
                    <b>{{ $t("message.p.user.p5.q") }}</b>
                    <br />
                    {{ $t("message.p.user.p5.a") }}

                    <router-link to="/help/interaction">
                        <font-awesome-icon icon="question" />
                        {{ $t("message.a.help") }}
                    </router-link>
                </div>
            </li>
        </ol>
    </div>
</template>



<script>

import UserService from "../services/user.service";

export default {
    name: "Download",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Installation';
    },
    mounted() {

    },
    methods: {
        Download: function () {
            UserService.download5().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'RFEX5Setup.msi'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "Installation",
                        admin: "Administrator",
                        user: "User (on each machine running Dlubal RFEM)",

                    },
                    a: {
                        licenses: "Licenses",
                        download: "Download",
                        help: "Help",
                    },
                    p: {
                        admin: {
                            p1: {
                                q: "Add Licenses",
                                a: "Add an email address and a password for each user here: "
                            },
                            p2: {
                                q: "Download RFEX",

                            },
                        },
                        user: {
                            p1: {
                                q: "Prerequisites",
                                a: "Install latest Dlubal RFEM5",
                            },
                            p2: {
                                q: "Install RFEX",
                                a: "Run",
                            },
                            p3: {
                                q: "Start Dlubal RFEM5",
                                a: "open any model file",
                            },
                            p4: {
                                q: "Start RFEX",
                                a: "RFEX is started via the Windows start menu. On the first run you will be asked for your credentials. Please provide the email address and password you have defined unter licenses.",
                            },
                            p5: {
                                q: "Next steps",
                                a: "You can find a quick introduction here: ",
                            }


                        }
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "Installation",
                        admin: "Administrator",
                        user: "Benutzer (auf jedem PC mit Dlubal RFEM)",
                    },
                    a: {
                        licenses: "Lizenzen",
                        download: "Download",
                        help: "Hilfe",
                    },
                    p: {
                        admin: {
                            p1: {
                                q: "Lizenzen hinzufügen",
                                a: "Für jeden Benutzer eine E-Mailadresse und Passwort festlegen: "
                            },
                            p2: {
                                q: "RFEX herunterladen",

                            },
                        },
                        user: {
                            p1: {
                                q: "Voraussetzungen",
                                a: "Aktuelle Version von Dlubal RFEM5 installieren",
                            },
                            p2: {
                                q: "RFEX installieren",
                                a: "Starte ",
                            },
                            p3: {
                                q: "Dlubal RFEM5 starten",
                                a: "und ein Modell öffnen",
                            },
                            p4: {
                                q: "RFEX starten",
                                a: "Beim ersten Aufruf werden die Anmeldedaten abgefragt. Bitte hier E-Mail Adresse und Passwort eingegeben, welches für diesen Benutzer unter Lizenzen definiert wurden.",
                            },
                            p5: {
                                q: "Nächste Schritte",
                                a: "Eine kurze Einführung startet hier: ",
                            }


                        }
                    }
                }
            },
        }
    },

};
</script>