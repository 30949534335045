<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <div class="navbar-nav mr-auto">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/" class="navbar-brand">RFEX</router-link>
          </li>

          <li class="nav-item">
            <router-link to="/" class="nav-link">
              <font-awesome-icon icon="home" />
              {{ $t("message.home") }}
            </router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="star" />
              {{ $t("message.features") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/features/modellink"
                class="dropdown-item"
              >{{ $t("message.modellink") }}</router-link>
              <router-link
                to="/features/substructures"
                class="dropdown-item"
              >{{ $t("message.substructures") }}</router-link>
              <router-link to="/features/ec3" class="dropdown-item">{{ $t("message.ec3") }}</router-link>
              <router-link to="/features/bim" class="dropdown-item">{{ $t("message.bim") }}</router-link>
              <router-link
                to="/features/modelling"
                class="dropdown-item"
              >{{ $t("message.modelling") }}</router-link>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="question" />
              {{ $t("message.help") }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/help/interaction"
                class="dropdown-item"
              >{{ $t("message.helpinteraction") }}</router-link>
              <router-link to="/help/modellink" class="dropdown-item">{{ $t("message.modellink") }}</router-link>
              <router-link
                to="/help/substructures"
                class="dropdown-item"
              >{{ $t("message.substructures") }}</router-link>
              <router-link to="/help/ec3" class="dropdown-item">{{ $t("message.ec3") }}</router-link>
              <router-link to="/help/modelling" class="dropdown-item">{{ $t("message.modelling") }}</router-link>
            </div>
          </li>

          <li v-if="loggedIn" class="nav-item">
            <router-link to="/licenses" class="nav-link">
              <font-awesome-icon icon="key" />
              {{ $t("message.licenses") }}
            </router-link>
          </li>

          <li v-if="loggedIn" class="nav-item">
            <router-link to="/download" class="nav-link">
              <font-awesome-icon icon="download" />
              {{ $t("message.installation") }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="navbar-nav ml-auto">
        <li v-if="!loggedIn" class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" />
            {{ $t("message.login") }}
          </router-link>
        </li>

        <li v-if="loggedIn" class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" />
            {{ $t("message.logout") }}
          </a>
        </li>

        <select v-model="$i18n.locale" class="form-select" data-width="fit">
          <option data-content="en">en</option>
          <option data-content="de">de</option>
        </select>
      </div>
    </nav>

    <div class="container">
      <router-view />
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <footer class="text-center text-lg-start bg-light text-muted fixed-bottom">
      <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
        {{ $t("message.footer") }}
        <a
          class="text-reset fw-bold"
          href="https://www.ztto.at/"
        >www.ztto.at</a> <br>
        <a href="https://www.ztto.at/index.html#legal" target="_blank">Impressum und Erklärung zur Informationspflicht</a>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  },

  i18n: { // `i18n` option, setup locale info for component
    messages: {
      en: {
        message: {
          "home": "Home",
          "features": "Features",
          "modellink": "Model link",
          "substructures": "Substructures",
          "ec3": "EC3",
          "bim": "BIM",
          "modelling": "Modelling tools",

          "help": "Help",

          "helpinteraction": "Interaction with RFEM",

          "licenses": "Licenses",
          "installation": "Installation",
          "login": "Login",
          "logout": "Logout",

          "footer": "RFEX - Extensions for Dlubal RFEM is a project developed by"
        }
      },
      de: {
        message: {
          "home": "Home",
          "features": "Funktionen",
          "modellink": "Verknüpfte Modelle",
          "substructures": "Substrukturen",
          "ec3": "EC3",
          "bim": "BIM",
          "modelling": "Modellierungstools",

          "help": "Hilfe",
          "helpinteraction": "Interaktion mit RFEM",

          "licenses": "Lizenzen",
          "installation": "Installation",
          "login": "Anmelden",
          "logout": "Abmelden",
          "footer": "RFEX - Erweiterungen für Dlubal RFEM ist ein Projekt entwickelt von"
        }
      },
    }
  },
};
</script>
