<template>
    <div class="col-md-12">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col"> {{ $t("message.email") }}</th>
                    <th scope="col"> {{ $t("message.actions") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(license, index) in licenses" :key="license.pk">
                    <td>{{ index + 1 }}</td>
                    <td>{{ license.email }}</td>

                    <td>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="ConfirmDeleteLicense(license.pk, license.email)"
                        >{{ $t("message.delete") }}</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div>
            <button type="button" class="btn btn-primary" @click="AddLicense()">{{ $t("message.add") }}</button>
        </div>

        <!-- Modal -->
        <div
            class="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            data-pk
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">{{ $t("message.confirm.title") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">...</div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("message.confirm.cancel") }}</button>
                        <button type="button" class="btn btn-danger" @click="DeleteLicense()">{{ $t("message.confirm.remove") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from "../services/user.service";
import jQuery from 'jquery';

let $ = jQuery;
export default {
    name: "Licenses",

    data() {
        return {
            title: "",
            licenses: []
        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - Licenses';
    },
    mounted() {
        this.GetLicenses();
    },
    methods: {
        AddLicense: function () {
            this.$router.push("/licenses/add");
        },

        GetLicenses: function () {

            UserService.getLicenses().then(
                (response) => {
                    this.title = "Licenses"
                    this.licenses = response.data;
                },
                (error) => {
                    this.title =
                        (error.response &&
                            error.response.data) ||
                        error.message ||
                        error.toString();
                    this.licenses = []
                }
            );
        },


        ConfirmDeleteLicense: function (pk, email) {
            $('#staticBackdrop').modal('show');
            $('#staticBackdrop').find('.modal-body').html(this.$t("message.confirm.body") + '<br><b>' + email + '</b> ?');
            $('#staticBackdrop').data('pk', pk);
        },

        DeleteLicense: function () {
            $('#staticBackdrop').modal('hide');
            var pk = $('#staticBackdrop').data('pk');
            UserService.deleteLicense(pk).then(
                () => {
                    this.GetLicenses();

                },
                (error) => {
                    this.title =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.licenses = []
                }
            );
        },


    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    "email":"Email",
                    "actions":"Actions",
                    "add": "Add License",
                    "delete": "Delete",
                    "confirm": {
                        "title": "Remove License",
                        "body": "Do you really want to remove the license for",
                        "cancel": "Cancel",
                        "remove":"Remove"
                    }
                }
            },
                        de: {
                message: {
                    "email":"E-Mail",
                    "actions":"Aktionen",
                                        "add": "Lizenz hinzufügen",
                    "delete": "Löschen",
                    "confirm": {
                        "title": "Licenz entfernen",
                        "body": "Wollen Sie wirklich diese Lizenz entfernen",
                        "cancel": "Abbrechen",
                        "remove":"Entfernen"
                    }
                }
            }
        }
    }
};
</script>