<template>
    <div class="col-md-12">
        <h2>{{ $t("message.h.title") }}</h2>
        <p>{{ $t("message.p.summary") }}</p>

        <h3>{{ $t("message.h.concept") }}</h3>
        <p>{{ $t("message.p.concept") }}</p>

        <h3>{{ $t("message.h.workflow") }}</h3>

        <ol>
            <li>{{ $t("message.p.workflow.p1") }}</li>
            <li>{{ $t("message.p.workflow.p2") }}</li>
            <li>{{ $t("message.p.workflow.p3") }}</li>
            <li>{{ $t("message.p.workflow.p4") }}</li>
            <li>{{ $t("message.p.workflow.p5") }}</li>
            <li>{{ $t("message.p.workflow.p6") }}</li>
            <li>{{ $t("message.p.workflow.p7") }}</li>
            <li>{{ $t("message.p.workflow.p8") }}</li>
            <li>{{ $t("message.p.workflow.p9") }}</li>
        </ol>
    </div>
</template>



<script>
export default {
    name: "FeatBim",

    data() {
        return {

        };
    },
    created() {
        document.title = process.env.NODE_ENV === 'development'
            ? 'DEVELOPMENT SERVER'
            : 'RFEX - ';
    },
    mounted() {

    },
    methods: {

    },
    i18n: { // `i18n` option, setup locale info for component
        messages: {
            en: {
                message: {
                    h: {
                        title: "BIM",
                        concept: "Main idea",
                        workflow: "Workflow",
                    },
                    a: {

                    },

                    p: {
                        summary: "Take control of the BIM Import/Export routines.",
                        concept: "RFEM works nicely with Tekla Structures. It is easy to exchange information between these two programs in both directions. Except for one thing: RFEM will automatically delete the link to Tekla if you run certain modifications on the member inside RFEM. This behaviour actually makes sense, because RFEM cannot be sure if you still refer to the same beam in Tekla. But this means in reality you actually cannot write back information to Tekla. To overcome this issue RFEX has a solution: The TEKLA GUID will be permanently stored and the BIM Link can be recreated at any time.",
                        workflow: {
                            p1: "Import the model data from Tekla to RFEM as usual",
                            p2: "Launch RFEX",
                            p3: "Switch to tab page SetOfMembers",
                            p4: "Select sets of members (it is safe to just select all)",
                            p5: "By clicking on Import Tekla Properties the TEKLA GUID will be read and stored on every set of members",
                            p6: "Click on Apply to save the information",
                            p7: "Any manipulation in RFEM you want is now possible",
                            p8: "Even adding a new set of members is possible by copying the TEKAL GUID from Tekla into the field PhysicalID of this new set of members.",
                            p9: "To recreate the BIM Link just select all sets of members and click on apply. The BIM Link will now be recreated from the stored TEKLA GUID and you can export any model data e. g. cross sections to Tekla."
                        }
                    }
                }
            },
            de: {
                message: {
                    h: {
                        title: "BIM",
                        concept: "Prinzip",
                        workflow: "Workflow",
                    },
                    a: {

                    },

                    p: {
                        summary: "Volle Kontrolle über den BIM Import/Export mit Tekla.",
                        concept: "Der Austausch von Informationen zwischen RFEM und Tekla funktioniert über die Schnittstelle problemlos in beiden Richtungen, aber es gibt einen Haken: RFEM wird automatisch den Bim Link löschen, sobald gewisse Operationen am Modell durchgeführt werden. Den Bim Link im Zweifelsfall zu löschen macht definitv Sinn, allerdings bringt das ein Problem mit sich: Da eine Nacharbeit am Modell fast immer notwendig ist, gehen somit die BIM Verknüpfungen in den meisten Fällen verloren. RFEX hat dafür eine Lösung: Die Tekla GUID wird in jedem Stabsatz permanent gespeichert, der Bim Link kann vor dem Exportieren neu erzeugt werden.",
                        workflow: {
                            p1: "Import des Tekla Modells nach RFEM wie gehabt.",
                            p2: "RFEX starten",
                            p3: "Wechseln zum Reiter SetOfMembers",
                            p4: "Stabsätze auswählen (z.B. alles auswählen)",
                            p5: "Das Anklicken von Import Tekla Properties wird die TEKLA ID auslesen und in den Stabsätzen abspeichern",
                            p6: "Apply anklicken, um Informationen dauerhaft zu speichern",
                            p7: "Modell kann jetzt frei bearbeitet werden",
                            p8: "Sogar neue Stabsätze können hinzugefügt werden. Einfach die TEKLA GUID des Stabes in dem Feld PhysicalID des neuen Stabsatzes eintragen.",
                            p9: "Um die Bim links wieder neu zu generieren, wieder alle Stabsätze selektieren und apply klicken. Bei diesem Schritt werden auch die Bim links jedes Mal wieder neu erzeugt. Modell Daten wie z.B. Querschnitte können nun problemlos nach TEKLA exportiert werden."
                        }
                    }
                }
            }
        }
    }
};
</script>